import React, { Component } from "react"
import css from "./stylesheets/LayoutBasic.module.scss"
import GlobalNavigation from "./GlobalNavigation"
import GlobalFooter from "./GlobalFooter"
import AdobeTypekit from "./AdobeTypekit"
import UrlCalc from "../utils/UrlCalc"
import AnchorScroll from "../utils/AnchorScroll"

class LayoutBasic extends Component {
  componentDidMount() {
    // 親コンポーネントの参照を受け取る
    if (this.props.reference) {
      this.props.reference.layoutBasicInstance = this
    }

    // Webfont を初期化
    if (!AdobeTypekit.initialized) {
      AdobeTypekit.init()
    }

    // ウェブフォントが読み込まれたらアンカースクロール
    if (typeof window !== "undefined") {
      let that = this
      this._fontLoadInterval = setInterval(() => {
        if (
          document
            .getElementsByTagName(`html`)[0]
            .classList.contains("wf-active")
        ) {
          clearInterval(that._fontLoadInterval)
          AnchorScroll.doScrollDown(UrlCalc.getHash(window.location.href))
        }
      }, 1000 / 10)
    }
  }

  // 親コンポーネントの参照を削除
  componentWillUnmount() {
    if (this.props.reference) {
      delete this.props.reference.layoutBasicInstance
    }
    clearInterval(this._fontLoadInterval)
  }

  /**
   * グローバルナビとローカルナビの高さを合算して戻します。
   * すこしマージンを取るようにしています。ハードコードしてすみません。
   * https://github.com/hgw/kaigo/issues/108
   *
   * @returns {number}
   * @private
   */
  _calcNaviHeight() {
    let result = 0
    if (typeof window !== "undefined") {
      let globalNavi = document.getElementById("globalNavi")
      let localNavi = document.getElementById("localNavi")
      if (localNavi) {
        return globalNavi.clientHeight + localNavi.clientHeight + 30 // 30 の padding 分ハードコードですみません！
      } else {
        return globalNavi.clientHeight // 30 の padding 分ハードコードですみません！
      }
    }
    return result
  }

  render() {
    this.ref = {}

    let styles = this.props.style ? this.props.style : {}

    return (
      <div>
        <GlobalNavigation
          home={false}
          reference={this.ref}
          lang={this.props.lang}
        />

        <div className={css.layoutBasic} style={styles}>
          {this.props.hasLocalNavi ? (
            <div className={css.childrenWithNavi}>{this.props.children}</div>
          ) : (
            <div className={css.children}>{this.props.children}</div>
          )}
        </div>
        {this.props.lang === "en" ? (
          <GlobalFooter>
            Project Subsidized by the Japan Ministry of Health, Labor and
            Welfare 2019
          </GlobalFooter>
        ) : (
          <GlobalFooter>令和元年度 厚生労働省補助事業</GlobalFooter>
        )}
      </div>
    )
  }
}
export default LayoutBasic
