const ignoreWakachigaki = [
  `、`,
  `。`,
  `「`,
  `」`,
  `『`,
  `』`,
  `（`,
  `）`,
  `・`,
  `$`,
]
function RubyConverterTool(string_) {
  string_ = string_.replace(/\{(.+?)\}/g, (all, group1, offset, string) => {
    const lastChar = group1.slice(-1)
    let className = `phrase`
    if (ignoreWakachigaki.includes(lastChar)) {
      className = `phrase withoutSpace`
    }
    if (lastChar === "$") {
      group1 = group1.slice(0, -1)
    }

    return "<span class='" + className + "'>" + group1 + "</span>"
  }) // {} を <span class"phrase"></span> に置き換え
  string_ = string_.replace(/\[(.+?)\]/g, "<ruby>$1</ruby>") // [] を <ruby></ruby> に置き換え
  string_ = string_.replace(
    /<ruby>(.+?)\//g,
    "<ruby><span class='rb'>$1</span>/"
  ) // 漢字部分を span でかこう
  string_ = string_.replace(
    /<\/span>\/(.+?)<\/ruby>/g,
    "</span><rt>$1</rt></ruby>"
  ) // () を <rt></tr> に置き換え

  return string_
}

export default RubyConverterTool
