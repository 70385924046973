import React from "react"
import RubyConverterTool from "../utils/RubyConverterTool"
/**
 * Related to https://github.com/hgw/kaigo/issues/2#issuecomment-580218337
 *
 * シンプルな表記ルールをルビタグに変換します。
 *
 * ## 新・やさしい日本語原稿表記ルール
 *
 *  - `{...}`
 *  - 半角の波括弧記号で、「フレーズ」を表します
 *  - この記号の前後でわかちがきをおこないます
 *  - この記号の間では改行しないようにします
 *  - `[漢字/ルビ]`
 *  - 半角の大かっこ `[` `]` と半角のスラッシュ `/` で漢字とよみの関係を表します
 *  - 自動的に HTML のルビタグに変換されます
 *
 *  ## やさしい日本語の本文中にカッコ（）が必要な場合
 *
 *  `統計(2016年度)によると、人口は減ってきています` という文章を表す場合
 *
 *  ### 原稿
 * ```
 * <RubyConvert>{`{[統計/とうけい](2016[年度/ねんど])に}{よると、}{[人口/じんこう]は[減/へ]ってきています}`}</RubyConvert>
 *
 * ```
 *
 * ## わかち書きの省略
 * わかち書きを入れたくない場合 `$` 記号を入れてください。わかち書きを省略します。
 * また 、。「」『』（）・ などの記号がわかち書きの末尾に来る場合、スペースの補完を行いません。
 * https://github.com/hgw/kaigo/issues/144
 * ```
 * {[満快/まんかい]のふる[郷/さと]}{さくら[発寒/はっさむ]$}
 * ```
 *
 *  @param props
 * @returns {*}
 */
export default props => {
  let content = RubyConverterTool(props.children)
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}
