import UrlCalc from "./UrlCalc"
import scrollToElement from "scroll-to-element"

class AnchorScroll {
  static doScrollDown(id_, duration=700, ease=`in-out-quart`) {
    let offsetHeight = AnchorScroll.calcNaviHeight() * -1
    scrollToElement(UrlCalc.getHash(id_), {
      offset: offsetHeight,
      duration: duration,
      ease: ease
    })
  }

  /**
   * グローバルナビとローカルナビの高さを合算して戻します。
   * すこしマージンを取るようにしています。ハードコードしてすみません。
   * https://github.com/hgw/kaigo/issues/108
   *
   * @returns {number}
   * @private
   */
  static calcNaviHeight() {
    let result = 0
    if (typeof window !== "undefined") {
      let globalNavi = document.getElementById("globalNavi")
      let localNavi = document.getElementById("localNavi")

      if (localNavi) {
        return globalNavi.clientHeight + localNavi.clientHeight + 30 // 30 の padding 分ハードコードですみません！
      } else {
        return globalNavi.clientHeight // 30 の padding 分ハードコードですみません！
      }
    }
    return result
  }
}

export default AnchorScroll
