class AdobeTypekit {
  static initialized = false

  static init() {
    if(AdobeTypekit.initialized){
      console.log('AdobeTypekit has already initialized')
      return
    }
    AdobeTypekit.initialized = true
    let _strScript = `(function(d) {
          var config = {
          kitId: 'dij3vox',
          scriptTimeout: 3000,
          async: true
        },
          h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\\bwf-loading\\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
        })(document);`

    this._script = document.createElement("script")
    this._script.type = "text/javascript"
    this._script.innerHTML = _strScript
    let head = document.getElementsByTagName("head")[0]
    head.appendChild(this._script)
  }
}

export default AdobeTypekit
