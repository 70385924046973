import React from "react"
import css from "./stylesheets/Info2column.module.scss"
import BasicText from "./BasicText"

export default props => {
  let cssBorder = css.wrapHasBorder
  if (props.noBorder) {
    cssBorder = " "
  }

  let rightWithSpPadding = ""
  if (props.rightWithSpPadding) {
    rightWithSpPadding = css.rightWithSpPadding
  }

  return (
    <div id={props.id}>
      <BasicText>
        <div className={css.wrap + ` ` + cssBorder}>
          <div className={css.left}>{props.children[0]}</div>
          <div className={css.right + ` ` + rightWithSpPadding}>
            {props.children[1]}
          </div>
        </div>
      </BasicText>
    </div>
  )
}
