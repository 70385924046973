import React, { Component } from "react"
import css from "./stylesheets/CenterContentLayout.module.scss"
class CenterContentLayout extends Component {
  render() {
    let basicColumnIsNarrow = ``
    if (this.props.narrow) {
      basicColumnIsNarrow = css.centerContentLayoutIsNarrow
    }

    let spFit = ``
    if(this.props.spFit){
      spFit = css.centerContentLayoutIsSpFit
    }

    return (
      <div className={css.centerContentLayout + ` ` + basicColumnIsNarrow + ` `+ spFit}>
        {this.props.children}
      </div>
    )
  }
}
export default CenterContentLayout
