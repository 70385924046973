import React, { Component } from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import css from "./stylesheets/GlobalNavigation.module.scss"
import Logo from "../images/logo_kij_white.svg"
import LogoRed from "../images/logo_kij_red.svg"
import IconFb from "../images/icon_fb.svg"
import IconUpDown from "../images/icon-updown-arw.svg"
import LanguageModal from "./LanguageModal"
import RubyConverterTool from "../utils/RubyConverterTool"
import { globalHistory } from "@reach/router"

class GlobalNavigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLanguageModalOpen: false,
      isMenuModalOpen: false,
      isSpHomeRedHeader: false,
      language: props.lang,
    }

    this.doToggleSpMenu = this.doToggleSpMenu.bind(this)
    this.doToggleLanguageMenu = this.doToggleLanguageMenu.bind(this)
  }

  // 親コンポーネントの参照を受け取る
  componentDidMount() {
    if (this.props.reference) {
      this.props.reference.globalNavigationInstance = this
    }
  }

  // 親コンポーネントの参照を削除
  componentWillUnmount() {
    if (this.props.reference) {
      delete this.props.reference.globalNavigationInstance
    }
  }

  getContentPath() {
    let result = ""
    result = globalHistory.location.pathname.replace(/(\/en|\/ja)/, "")
    return result
  }

  // API
  openLanguageMenu() {
    this.setState(state => ({
      isLanguageModalOpen: true,
    }))
  }

  doToggleLanguageMenu(e) {
    this.setState(state => ({
      isLanguageModalOpen: !state.isLanguageModalOpen,
    }))
  }

  doToggleSpMenu(e) {
    this.setState(state => ({
      isLanguageModalOpen: false,
      isMenuModalOpen: !state.isMenuModalOpen,
    }))
  }

  spHomeRedHeaderMode(bool_) {
    if (this.state.isSpHomeRedHeader !== bool_) {
      this.setState(state => ({
        isSpHomeRedHeader: bool_,
      }))
    }
  }

  checkCurrent(targetPath_) {
    let current = globalHistory.location.pathname
    return current.includes(targetPath_)
  }

  render() {
    const styleSpMenuVisible = this.state.isMenuModalOpen
      ? css.globalNavigationMenuIsSpMenuVisible
      : css.globalNavigationMenuIsSpMenuInvisible

    const styleCloseButton = this.state.isMenuModalOpen
      ? css.buttonSpMenuIsClose
      : ""

    let langDir = ""
    this.strLanguage = RubyConverterTool(`やさしい[日本語/にほんご]`)
    if (this.state.language === `en`) {
      langDir = "/en"
      this.strLanguage = "English "
    } else if (this.state.language === `ja`) {
      langDir = "/ja"
      this.strLanguage = "日本語 "
    }

    const map = [
      {
        to: langDir + `/what-is-kaigo/`,
        str: `WHAT is KAIGO?`,
      },
      {
        to: langDir + `/interview/`,
        str: `INTERVIEW`,
      },
      {
        to: langDir + `/basic-information/`,
        str: `BASIC INFORMATION`,
      },
      {
        to: langDir + `/faq/`,
        str: `FAQ`,
      },
      {
        to: langDir + `/news/`,
        str: `NEWS`,
      },
    ]
    let linkList = []
    for (let i = 0; i < map.length; i += 1) {
      let classCurrent = ""
      if (this.checkCurrent(map[i].to)) {
        classCurrent = css.linkIsCurrent
      }
      linkList.push(
        <li key={i}>
          <Link to={map[i].to} className={css.link + ` ` + classCurrent}>
            {map[i].str}
          </Link>
        </li>
      )
    }

    let globalNavigationIsHome = ""
    let buttonSwitchLanguageIsHome = ""
    let buttonSpMenuIsHome = ""
    let logoIsHome = ""
    let logoRedIsHome = ""

    let isModalOpen =
      this.state.isLanguageModalOpen || this.state.isMenuModalOpen

    // todo: 赤白ロゴの表示・非表示のロジックが美しくないので書き直したほうがよい #53
    // https://github.com/hgw/kaigo/issues/53
    if (this.props.home && !isModalOpen) {
      globalNavigationIsHome = css.globalNavigationIsHome
      buttonSwitchLanguageIsHome = css.buttonSwitchLanguageIsHome
      buttonSpMenuIsHome = css.buttonSpMenuIsHome
      logoRedIsHome = css.logoRedIsHome
      logoIsHome = css.logoIsHome
    }
    if (isModalOpen) {
      logoRedIsHome = css.logoRedIsHomeHidden
    }

    let contentPath = this.getContentPath()

    let globalNavigationIsRed = ``
    if (globalNavigationIsHome && this.state.isSpHomeRedHeader) {
      globalNavigationIsRed = css.globalNavigationIsRed
      buttonSwitchLanguageIsHome = ""
      buttonSpMenuIsHome = ""
      logoRedIsHome = ""
      logoIsHome = ""
    }

    return (
      <div
        className={
          css.globalNavigation +
          ` ` +
          globalNavigationIsHome +
          ` ` +
          globalNavigationIsRed
        }
        id={`globalNavi`}
        ref={this.node}
      >
        <h1>
          <Link to={langDir + `/`} className={`hoverWithOpacity `}>
            <img
              src={Logo}
              alt={this.props.site.siteMetadata.title}
              className={css.logo + ` ` + logoIsHome}
            />
            <img
              src={LogoRed}
              alt={this.props.site.siteMetadata.title}
              className={css.logoRed + ` ` + logoRedIsHome}
            />
          </Link>
        </h1>

        <ul className={css.globalNavigationMenu + " " + styleSpMenuVisible}>
          {linkList}
          <li>
            <a
              href={this.props.site.siteMetadata.facebookLink}
              rel={`noopener noreferrer`}
              target={`_blank`}
              className={`withoutBlankIcon hoverWithOpacity`}
            >
              <img src={IconFb} alt="facebook" />
            </a>
          </li>
        </ul>

        <button
          className={
            `hoverWithOpacity ` +
            css.buttonSwitchLanguage +
            ` ` +
            buttonSwitchLanguageIsHome
          }
          onClick={this.doToggleLanguageMenu}
        >
          <span>LANGUAGES</span>
          <img src={IconUpDown} alt="" />
        </button>

        <button
          className={
            `resetButton ` +
            css.buttonSpMenu +
            ` ` +
            styleCloseButton +
            ` ` +
            buttonSpMenuIsHome
          }
          onClick={this.doToggleSpMenu}
        >
          <span>メニュー</span>
        </button>

        {this.state.isLanguageModalOpen ? (
          <LanguageModal
            doChangeListener={this.doToggleLanguageMenu}
            visible={true}
            lang={this.state.language}
            contentPath={contentPath}
          />
        ) : (
          <LanguageModal
            doChangeListener={this.doToggleLanguageMenu}
            visible={false}
            lang={this.state.language}
            contentPath={contentPath}
          />
        )}
      </div>
    )
  }
}

export default prop => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            facebookLink
          }
        }
      }
    `}
    render={data => (
      <GlobalNavigation
        site={data.site}
        file={data.file}
        home={prop.home}
        reference={prop.reference}
        lang={prop.lang}
        ref={prop.ref}
      />
    )}
  />
)
