import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import css from "./stylesheets/GlobalFooter.module.scss"

class GlobalFooter extends Component {
  render() {
    return (
      <div className={css.base}>
        <div className={css.left}>
          &copy; {this.props.site.siteMetadata.author}
        </div>
        <div className={css.right}>{this.props.children}</div>
      </div>
    )
  }
}
export default prop => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `}
    render={data => (
      <GlobalFooter site={data.site}>{prop.children}</GlobalFooter>
    )}
  />
)
