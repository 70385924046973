import React, { Component } from "react"
import css from "./stylesheets/LanguageModal.module.scss"
import RubyConvert from "./RubyConvert"

class LanguageModal extends Component {
  constructor(props) {
    super(props)
    this.doOkClick = this.doOkClick.bind(this)
    this.doLangClick = this.doLangClick.bind(this)
    this.state = {
      myLanguage: this.props.lang,
    }
  }
  doOkClick() {
    // 言語が変わってなかったらそのまま終了
    if (this.props.lang === this.state.myLanguage) {
      this.props.doChangeListener()
    } else {
      const targetDirectory =
        this.state.myLanguage === "easyja" ? "/" : "/" + this.state.myLanguage
      if (targetDirectory !== "/") {
        window.location.href = targetDirectory + this.props.contentPath
      } else {
        window.location.href = this.props.contentPath
      }
    }
  }

  /**
   * 言語切替
   * @param e
   */
  doLangClick(e) {
    const language = e.currentTarget.dataset.targetlanguage
    this.setState(state => ({
      myLanguage: language,
    }))
  }

  render() {
    if (this.props.visible) {
      this.visibleClass = css.languageModalIsVisible
    } else {
      this.visibleClass = css.languageModalIsInvisible
    }

    let classEasyJa =
      this.state.myLanguage === "easyja" ? css.langButtonIsActive : ""
    let classJa = this.state.myLanguage === "ja" ? css.langButtonIsActive : ""
    let classEn = this.state.myLanguage === "en" ? css.langButtonIsActive : ""

    return (
      <div className={css.languageModal + " " + this.visibleClass}>
        <ul>
          <li className={css.languageModalItem}>
            <button
              data-targetlanguage={`easyja`}
              onClick={this.doLangClick}
              className={`resetButton ` + css.langButton + " " + classEasyJa}
            >
              <RubyConvert>{`やさしい[日本語/にほんご]`}</RubyConvert>
            </button>
            <p>
              <RubyConvert>{`{この} {[言葉/ことば]を} {[選/えら]ぶと} {このように} {[見/み]えます。}`}</RubyConvert>

              <span className={`phrase`}>
                <a
                  href={`https://www.jlpt.jp/about/levelsummary.html`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  <RubyConvert>{`[日本語能力試験/にほんごのうりょくしけん] `}</RubyConvert>
                </a>
                では
              </span>

              <RubyConvert>{`{N4くらいの} {レベルです。}`}</RubyConvert>

              <br />
            </p>
          </li>
          <li className={css.languageModalItem}>
            <button
              data-targetlanguage={`ja`}
              onClick={this.doLangClick}
              className={"resetButton " + css.langButton + " " + classJa}
            >
              日本語
            </button>
            <p>
              この言語を選択すると、このように表示されます。
              <br />
              日本語能力試験では、N2程度のレベルです。
            </p>
          </li>
          <li className={css.languageModalItem}>
            <button
              disabled={false}
              data-targetlanguage={`en`}
              onClick={this.doLangClick}
              className={"resetButton " + css.langButton + " " + classEn}
            >
              English
            </button>
            <p>This is what you see when you select this language.</p>
          </li>
        </ul>

        <p className={css.okButton}>
          <button onClick={this.doOkClick} className={css.okButton}>
            OK
          </button>
        </p>
      </div>
    )
  }
}
export default LanguageModal
